import "../Styles/NewArrivals.css";
import { FiCalendar } from "react-icons/fi";
import { TbManualGearbox } from "react-icons/tb";
import { PiGasCan } from "react-icons/pi";
import { IoSpeedometerOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import AOS from "aos";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import { baseUrl } from "../config";
import { baseUrlForStorage } from "../config";

AOS.init({
  once: false,
});

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "1px solid #ccc",
    background: "#fff",
    overflow: "auto",
    WebkitOverflowScrolling: "touch",
    borderRadius: "4px",
    outline: "none",
    padding: "20px",
    // Add any additional style here
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    backdropFilter: "blur(5px)", // Apply blur effect
    // Animation for fade-in
    animation: "fadeIn 0.5s ease-in-out",
  },
};

const NewArrivals = () => {
  const [data, setData] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedCarTitle, setSelectedCarTitle] = useState("");
  const [showContactOptions, setShowContactOptions] = useState(false);
  const [currentPhoneNumber, setCurrentPhoneNumber] = useState("");
  const [visibleContactOptionId, setVisibleContactOptionId] = useState(null);

  const openContactOptions = (id, phone) => {
    setCurrentPhoneNumber(phone);
    setVisibleContactOptionId(id); // Update the state to show contact options for the specific car
  };

  const closeContactOptions = () => {
    setVisibleContactOptionId(null); // This will hide the contact options
  };

  useEffect(() => {
    const handleScroll = () => {
      setShowContactOptions(false);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const openModal = (title) => {
    setSelectedCarTitle(title);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    const appElement = document.querySelector(".App");
    if (modalIsOpen) {
      appElement.classList.add("modal-open");
    } else {
      appElement.classList.remove("modal-open");
    }
  }, [modalIsOpen]);

  useEffect(() => {
    fetch(`${baseUrl}car-listings/latest-three`)
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((error) => console.error(error));
  }, []);
  return (
    <>
      <div className="new-arrivals py-5">
        <div className="container-xl">
          <h1
            className="display-5 fw-normal m-0 text-center pt-4 mb-5"
            data-aos="zoom-in-up"
          >
            New Arrivals
          </h1>

          <div className="new-arrivals-features d-flex align-items-center justify-content-center  flex-wrap gap-2">
            {data &&
              data.map((ele) => {
                return (
                  <div
                    key={ele.id}
                    className="new-arrival-feature"
                    data-aos="flip-left"
                    data-aos-delay="100"
                  >
                    
                    <Link  
                        to={`/car-details/${ele.id}`}> 
                          <div className="new-arrival-feature-img">
                      <img src={`${baseUrlForStorage}/${ele.image}`} alt="" />
                    </div></Link>

                    
                  
                    <div className="title-and-price">
                    <Link  
                        to={`/car-details/${ele.id}`}> 
                         <h3 className="fs-6 fw-bold ps-2 pt-2">{ele.title}</h3>
                        </Link>
                     
                      <h3 className="fs-6 fw-bold pe-2 pt-2 price">
                        {ele.price} AED
                      </h3>
                    </div>{" "}
                    <div className="new-arrival-infos d-flex align-items-center justify-content-between  mx-2 ">
                      <div className="info d-flex align-items-center justify-content-center gap-1 ">
                        <FiCalendar />
                        <p className="m-0">{ele.year}</p>
                      </div>
                      <div className="info d-flex align-items-center justify-content-center gap-1 ">
                        <TbManualGearbox />

                        <p className="m-0">{ele.gear_type}</p>
                      </div>
                      <div className="info d-flex align-items-center justify-content-center gap-1 ">
                        <PiGasCan />

                        <p className="m-0">{ele.fuel_type}</p>
                      </div>
                      <div className="info d-flex align-items-center justify-content-center gap-1 ">
                        <IoSpeedometerOutline />
                        <p className="m-0">{ele.mileage} </p>
                      </div>
                    </div>
                    <div className="new-arrival-btns d-flex align-items-center justify-content-center gap-2 mx-2 my-2">
                      <button
                        className="py-2"
                        onClick={() => openContactOptions(ele.id,ele.phone)}
                      >
                        I’m Interested
                      </button>
                      {visibleContactOptionId === ele.id && (
                          <div className="contact-options-container">
                              <button className="btn-close" onClick={closeContactOptions}>Close</button>

                            <div className="contact-info">
                              <h3 className="contact-title">
                                {ele.car_model.manufacturer["name"]}
                              </h3>
                              <h3 className="contact-title">
                                {ele.car_model["name"]}
                              </h3>

                              <p className="contact-name">
                                {ele.owner["name"]}
                              </p>
                              <p className="operational-hours">Seller</p>
                            </div>
                            <a
                              href={`tel:${currentPhoneNumber}`}
                              className="contact-option phone-call-button"
                            >
                              <span className="button-text-call">Call Now</span>
                            </a>
                            <a
                              href={`https://wa.me/${currentPhoneNumber}?text=Hello, I'm interested in your car. Car Title: ${
                                ele.title +
                                " Year: " +
                                ele.year +
                                " Price " +
                                ele.price
                              } `}
                              className="contact-option whatsapp-button"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span className="button-text-call">
                                Message on WhatsApp
                              </span>
                            </a>
                          </div>
                        )}
                      <Link
                        className="link-view-button"
                        to={`/car-details/${ele.id}`}
                      >
                        View
                      </Link>
                    </div>
                  </div>
                );
              })}
          </div>
          

          <Link
            to="/Carsforsale" // Update this path to your actual stock page route
            className="d-block text-center view-stock-btn mt-5 "
            data-aos="zoom-out"
          >
            View Stock{" "}
          </Link>
        </div>
      </div>
    </>
  );
};

export default NewArrivals;
