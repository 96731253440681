import "../Styles/SingleBlogPost.css";
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { baseUrlForStorage } from '../config';
import { baseUrl } from '../config';
import Header from "../Comp/Header";
import Footer from "../Comp/Footer";

const SingleBlogPost = () => {
  const { id } = useParams();
  const [blogPost, setBlogPost] = useState(null);

  useEffect(() => {
    const fetchBlogPost = async () => {
      try {
        const response = await fetch(`${baseUrl}blog-posts/${id}`);
        const data = await response.json();
        setBlogPost(data);
      } catch (error) {
        console.error("Failed to fetch blog post", error);
      }
    };

    fetchBlogPost();
  }, [id]);

  if (!blogPost) {
    return <div>Loading...</div>;
  }

  return (
    
    <div>
        <div className="about-us-img">
          <img
            src={`${baseUrlForStorage}/${blogPost.image} `}
            alt=""
            className="about-us-image"
          />
          <h1 className="display-2 about-us-title text-light fw-bold ">
          {blogPost.title}
          </h1>
          <div className="head-container">
            <div className="container-xxl pt-3">
              <Header />
            </div>
          </div>
        </div>
        <h1 style={{ textAlign: 'center', margin: '20px 0', fontSize: '2.5rem' }}>{blogPost.title}</h1>
    
      <div className="blog-container" dangerouslySetInnerHTML={{ __html: blogPost.body }} />
      {/* Display other blog post details as needed */}
      <Footer />

    </div>
  );
};

export default SingleBlogPost;
