import "./App.css";
import CarsForSale from "./Pages/CarsForSale";
import Home from "./Pages/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";
import CarDetails from "./Pages/CarDetails";
import SellYourCar from "./Pages/SellYourCar";
import Services from "./Pages/Services";
import AboutUs from "./Pages/AboutUs";
import ContactUs from "./Pages/ContactUs";
import Blog from "./Pages/Blog";
import SingleBlogPost from "./Pages/SingleBlogPost";
import { FloatingWhatsApp } from 'react-floating-whatsapp'

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#000000",
      },
    },
  });

  return (
    <div className="App">
       <FloatingWhatsApp 
        phoneNumber="+213779552450" 
        accountName="Bargugzai Motors"
        avatar="https://firebasestorage.googleapis.com/v0/b/zakaria-1564219085716.appspot.com/o/WhatsApp%20Image%202024-04-03%20%C3%A0%2023.34.48_b04431e0.jpg?alt=media&token=f1b3d7e8-b4c7-43d5-8d7a-52a6c55b54ca"
        statusMessage="Typically replies within 1 hour"
        chatMessage="Hello! How can we help you?" 
        placeholder="Type a message..." 
        darkMode={false} 
        allowClickAway={true}
        allowEsc={true}
        notification={true} 
        notificationDelay={60000} 
        notificationSound={false} 
        height={300} 
        width={280}
        headerTitle="Chat with us on WhatsApp!" 
        headerColor="#128C7E" 
        backgroundColor="#25D366" 
        position="right"
       />
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          {" "}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/carsforsale" element={<CarsForSale />} />
            <Route path="/car-details/:id" element={<CarDetails />} />
            <Route path="/SellYourCar" element={<SellYourCar />} />
            <Route path="/Services" element={<Services />} />
            <Route path="/AboutUs" element={<AboutUs />} />
            <Route path="/ContactUs" element={<ContactUs />} />
            <Route path="/Blog" element={<Blog />} />
            <Route path="/SingleBlogPost/:id" element={<SingleBlogPost />} />

          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
